@import "../styles/mixins/fonts";

.main {
  &__wrapper {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }

  &__nav-bar {
    border-right: 1px solid var(--black--80);
  }

  &__content {
    width: 100%;
    overflow: auto;
  }

  &__container {
    padding: 24px 32px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1280px;
  }
}

.box {
  margin-top: 16px;
  background: linear-gradient(0deg, var(--white--12), var(--white--12)), var(--second-b1);
  filter: drop-shadow(0px 8px 10px var(--black--14)) drop-shadow(0px 3px 14px var(--black--12)) drop-shadow(0px 5px 5px var(--black--20));

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 4px;

    &:hover {
      background-color: var(--second-b2--40);
    }

    & + .box__item {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        height: 1px;
        width: calc(100% - 32px);
        background-color: var(--white--12);
      }
    }
  }

  &__empty {
    padding-left: 16px;
    padding-right: 4px;
  }

  &__link {
    padding: 12px 0;
    width: 100%;

    @include body-text;

    color: var(--white--87);

    &:first-letter {
      text-transform: capitalize;
    }
  }

  &__chip {
    margin-right: 8px;

    @include small-text;

    text-transform: uppercase;
    color: var(--white--87);

    &.MuiChip-root {
      background-color: var(--dark-blue--50);
    }
  }
}
