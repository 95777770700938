@import "../../styles/mixins/fonts";

.feature-branch {
  &__info {
    display: flex;
    align-items: center;
  }

  &__header {
    margin: 16px 0 32px 0;
    display: flex;
    align-items: center;
  }

  &__item {
    color: white;

    &-star {
      margin-right: 10px;
      cursor: pointer;
      display: none;

      &--active {
        display: inline-block;
      }
    }

    &:hover {
      .feature-branch__item-star {
        opacity: 0.3;
        display: inline-block;
      }
    }
  }

  &__search {
    width: 360px;
  }

  &__toast {
    font-size: 18px;
  }

  &__test-status {
    margin-right: 8px;

    @include small-text;

    text-transform: uppercase;
    color: var(--white--87);

    &--running {
      background-color: #439EF4;
    }

    &--passed {
      background-color: #00c164;
    }

    &--falied {
      background-color: #ff5272;
    }

    &--queued {
      background-color: #a3a2ad;
    }
  }
}

.service-tooltip {
  max-height: 208px;
  overflow-x: auto;
  padding-right: 12px;
}

.service-title {
  white-space: pre-wrap;
  font-size: 12px;

  min-width: 120px;

  &__main {
    text-align: center;
    margin-bottom: 12px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__item {
    display: inline-flex;
    justify-content: space-between;
    gap: 24px;
  }
}

.test-status-tooltip {
  padding: 8px;
  font-size: 12px;
}
