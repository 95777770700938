@import "../../styles/mixins/fonts";

@import-normalize;

.nav-bar {
  padding: 16px 0;
  height: 100%;
  width: 300px;
  background: linear-gradient(0deg, var(--white--15), var(--white--15)), var(--second-b1);
  box-shadow: 0 16px 24px var(--black--14), 0 6px 30px var(--black--12), 0 8px 10px var(--black--20);
  overflow: auto;

  &__avatar {
    width: 40px;
    height: 40px;
    margin: 24px 16px 30px;
  }

  &__title {
    padding: 0 16px;
    margin-bottom: 32px;

    @include title-small;

    color: var(--white--87);
  }

  &__list {
    display: flex;
    flex-direction: column;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      height: 1px;
      width: 100%;
      background-color: var(--white--12);
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    color: var(--white--60);

    @include subtitle;

    &:hover {
      background-color: var(--second-b2--40);
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 32px;
    margin-left: 16px;
    color: var(--white--60);
  }
}