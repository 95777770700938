@mixin small-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

@mixin buttons {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

@mixin subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

@mixin body-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin title-small {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

@mixin subtitle-big {
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
}

@mixin title-big {
  font-weight: 400;
  font-size: 34px;
  line-height: 36px;
}