@import "../../styles/mixins/fonts";

.dialog {
  &__title {
    padding-top: 24px;
    padding-bottom: 8px;

    & h2 {
      @include body-text;

      color: var(--white--87);
    }
  }

  &__buttons {
    padding-bottom: 24px;
  }

  &__btn {
    @include buttons;

    color: var(--light-blue);
  }
}