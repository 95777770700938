:root {
  --black: #292929;
  --white: #ffffff;

  --black--12: rgba(0, 0, 0, 0.12);
  --black--14: rgba(0, 0, 0, 0.14);
  --black--20: rgba(0, 0, 0, 0.2);
  --black--80: rgba(0, 0, 0, 0.8);

  --white--12: rgba(255, 255, 255, 0.12);
  --white--15: rgba(255, 255, 255, 0.15);
  --white--23: rgba(255, 255, 255, 0.23);
  --white--60: rgba(255, 255, 255, 0.6);
  --white--70: rgba(255, 255, 255, 0.7);
  --white--87: rgba(255, 255, 255, 0.87);

  --dark-blue--50: rgba(5, 0, 55, 0.5);

  --light-blue: #91beff;

  --second-b1: #121212;
  --second-b2: #111111;

  --second-b2--40: rgba(17, 17, 17, 0.4);
}
