.box {
  &__loader {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.search {
  display: flex;
  justify-content: flex-end;
}
