@import "../../styles/mixins/fonts";

.branch {
  &__loader {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__header {
    margin: 16px 0 32px 0;
    height: 56px;
    display: flex;
    align-items: center;
  }

  &__toast {
    font-size: 18px;
  }
}

.content {
  &__title {
    margin: 8px 0 8px 0;

    display: flex;
    justify-content: space-between;

    @include subtitle-big;

    color: var(--white);
  }

  &__no-margin {
    margin: 0;
  }

  &__buttons-group {
    display: flex;
    gap: 8px;
  }

  &__button {
    text-transform: capitalize;
  }
}

.branch-info {
  width: 100%;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include body-text;
    color: var(--white);
  }

  &__chip {
    @include body-text;

    &:hover {
      background-color: var(--second-b2--40);
      opacity: 0.9;
      cursor: pointer;
    }
  }
}

.test-info {
  width: 100%;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include body-text;
    color: var(--white);
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__position-wrapper {
    width: 30px;
  }

  &__status-wrapper {
    width: 100px;
  }

  &__status {
    margin-right: 8px;

    @include small-text;

    text-transform: uppercase;
    color: var(--white--87);

    &--running {
      background-color: #439EF4;
    }

    &--passed {
      background-color: #00c164;
    }

    &--failed {
      background-color: #ff5272;
    }

    &--unknown {
      background-color: #a3a2ad;
    }

    &--queued {
      background-color: #a3a2ad;
    }
  }
}
