@import "../../styles/mixins/fonts";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;

  &__title {
    margin-right: auto;

    @include title-big;

    color: var(--white);
  }

  &__link {
    margin-right: 24px;
    color: var(--white--87);

    & a {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__main-btn.MuiButtonBase-root {
    margin-left: 24px;
  }
}