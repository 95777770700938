.accordion {
  &.MuiAccordion-root {
    margin-top: 0;
    background-color: transparent;
    box-shadow: none;
  }

  & .MuiAccordionSummary-root.Mui-expanded {
    border-top: 1px solid var(--white--15);
  }

  &.MuiAccordion-root.Mui-expanded {
    margin-top: 0;
  }

  &--left {
    margin-left: 58px;

    &.MuiAccordion-root.Mui-expanded {
      margin-left: 58px;
    }
  }

  &__details {
    &.MuiAccordionDetails-root {
      display: block;
    }
  }
}