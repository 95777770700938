.dropdown-menu-button {
  position: relative;
  flex-shrink: 0;
  color: var(--white--87);

  &--with-loader {
    &.MuiButton-text {
      padding-right: 30px;
    }
  }

  &__loader {
    position: absolute;
    right: 5px;
  }
}

.popper {
  background: linear-gradient(0deg, var(--white--12), var(--white--12)), var(--second-b1);
  box-shadow: 0 4px 10px var(--black--14), 0 2px 14px var(--black--12), 0 2px 5px var(--black--20);
  max-height: 300px;
  overflow: auto;

  &__item {
    color: var(--white--87);
  }
}
