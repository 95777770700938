@import "../../styles/mixins/fonts";
@import "../../styles/variables";

.service-box {
  border-radius: 4px;

  &.MuiBox-root {
    padding-top: 0;
  }

  & .MuiTabs-root {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: linear-gradient(0deg, var(--white--15), var(--white--15)), var(--second-b1);
  }
}

.service-config {
  &__box.MuiBox-root {
    padding: 0;
  }

  &__paper {
    display: flex;
    padding: 8px 0;
  }

  &__block {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 24px;
  }

  &__title {
    @include subtitle-big;

    color: var(--white--87);
  }

  &__title-container {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    min-height: 38px;
  }

  &__switch-container {
    display: flex;
    align-items: center;
    margin-right: auto;
  }

  &__accordion {
    display: flex;

    &.MuiPaper-elevation1 {
      box-shadow: none;
    }
  }

  &__accordion-item {
    display: flex;
    gap: 16px;
  }

  &__ace-container {
    height: 100%;
    margin-bottom: 3px;
  }

  &__ace-fieldset {
    height: 100%;
    margin-top: 3px;
    padding: 15px 8px;
    border-radius: 4px;
    border: 1px solid var(--white--23);
  }

  &__ace-legend {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    line-height: 1;
    color: var(--white--70);
  }

  &__ace-editor {
    min-height: 100px;
  }
}

.accordion-wrapper-items {
  display: flex;
  justify-content: flex-end;
}
